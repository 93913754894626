var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loadingAction.getCarbonProject ? _c('div', {
    staticClass: "text-center pt-5"
  }, [_c('Spinner')], 1) : _c('div', [_c('PageHeaderDisplay', {
    attrs: {
      "title": _vm.carbon.project.name,
      "sub-title": _vm.carbon.project.shortDescription,
      "category": "Carbon Projects",
      "category-logo": "fa-trees",
      "category-route": {
        name: 'marketplace-carbon-list'
      }
    }
  }), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-7"
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('img', {
    staticClass: "image",
    attrs: {
      "src": _vm.carbon.project.images[0].url
    }
  })]), _c('div', [_c('p', [_vm._v(_vm._s(_vm.descriptionParagraphs[0]))]), _vm.carbon.project.images[1] ? _c('img', {
    staticClass: "image mb-4",
    attrs: {
      "src": _vm.carbon.project.images[1].url
    }
  }) : _vm._e(), _vm._l(_vm.descriptionParagraphs.slice(1), function (paragraph, idx) {
    return _c('p', {
      key: idx
    }, [_vm._v(_vm._s(paragraph))]);
  }), _vm.carbon.project.images.length > 2 ? _c('div', {
    staticClass: "image-list mb-5"
  }, _vm._l(_vm.carbon.project.images.slice(2), function (image) {
    return _c('img', {
      key: image.url,
      staticClass: "image-sm",
      attrs: {
        "src": image.url
      }
    });
  }), 0) : _vm._e()], 2)]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "mb-5"
  }, [_c('SectionTitle', {
    attrs: {
      "actions-class": "col-md-4"
    }
  }, [[_vm._v("Purchase")]], 2), _c('div', {
    staticClass: "mb-3"
  }, [_vm.form.type === 'money' ? _c('div', {
    staticClass: "input-group"
  }, [_c('FormItem', {
    staticClass: "col-md-3",
    attrs: {
      "id": "type",
      "alt": "",
      "type": "select",
      "options": _vm.purchaseTypeOptions
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _c('FormItem', {
    attrs: {
      "id": "money"
    },
    model: {
      value: _vm.form.money,
      callback: function ($$v) {
        _vm.$set(_vm.form, "money", $$v);
      },
      expression: "form.money"
    }
  }), _c('FormItem', {
    staticClass: "col-md-3",
    attrs: {
      "id": "currency",
      "alt": "",
      "type": "select",
      "options": _vm.currencyOptions
    },
    model: {
      value: _vm.form.currency,
      callback: function ($$v) {
        _vm.$set(_vm.form, "currency", $$v);
      },
      expression: "form.currency"
    }
  })], 1) : _c('div', {
    staticClass: "input-group"
  }, [_c('FormItem', {
    staticClass: "col-md-3",
    attrs: {
      "id": "type",
      "alt": "",
      "type": "select",
      "options": _vm.purchaseTypeOptions
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _c('FormItem', {
    attrs: {
      "id": "carbon"
    },
    model: {
      value: _vm.form.carbon,
      callback: function ($$v) {
        _vm.$set(_vm.form, "carbon", $$v);
      },
      expression: "form.carbon"
    }
  }), _c('FormItem', {
    staticClass: "col-md-3",
    attrs: {
      "id": "unit",
      "alt": "",
      "type": "select",
      "options": _vm.carbonUnitOptions
    },
    model: {
      value: _vm.form.unit,
      callback: function ($$v) {
        _vm.$set(_vm.form, "unit", $$v);
      },
      expression: "form.unit"
    }
  })], 1)]), _c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('span', {
    staticClass: "font-w600 font-size-lg mr-2"
  }, [_vm._v("$" + _vm._s(_vm.purchaseTotals.money) + " USD")]), _c('span', {
    staticClass: "text-muted text-capitalize"
  }, [_vm._v(_vm._s(_vm.purchaseTotals.carbon) + " " + _vm._s(_vm.form.unit))]), _c('p', {
    staticClass: "mb-0 small font-w600 text-muted"
  }, [_vm._v("Vintage Year " + _vm._s(_vm.carbon.project.activeTranche.vintageYear))])]), _c('div', {
    staticClass: "col-lg-4 text-right"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        _vm.constructionModal = true;
      }
    }
  }, [_vm._v("Add to Cart")])])])], 1), _c('div', {
    staticClass: "mb-5"
  }, [_c('SectionTitle', [_vm._v("Project Details")]), _c('PropertyListItem', {
    staticClass: "mb-3",
    attrs: {
      "col-size": "col-md-4",
      "title": "Location"
    }
  }, [_vm._v(_vm._s(_vm.carbon.project.city) + ", " + _vm._s(_vm.carbon.project.country))]), _c('PropertyListItem', {
    staticClass: "mb-3",
    attrs: {
      "col-size": "col-md-4",
      "title": "Methodology Type"
    }
  }, [_vm._v(_vm._s(_vm.carbon.project.type))]), _c('PropertyListItem', {
    staticClass: "mb-3",
    attrs: {
      "col-size": "col-md-4",
      "title": "Mechanism"
    }
  }, [_vm._v(_vm._s(_vm.carbon.project.offsetClassification))]), _c('PropertyListItem', {
    staticClass: "mb-3",
    attrs: {
      "col-size": "col-md-4",
      "title": "Registry"
    }
  }, [_vm._v(_vm._s(_vm.carbon.project.registryName))]), _c('PropertyListItem', {
    staticClass: "mb-3",
    attrs: {
      "col-size": "col-md-4",
      "title": "Project ID"
    }
  }, [_vm._v(_vm._s(_vm.carbon.project.registryProjectId))]), _c('PropertyListItem', {
    staticClass: "mb-3",
    attrs: {
      "col-size": "col-md-4",
      "title": "Ratings"
    }
  }, [_vm._l(_vm.carbon.project.projectRatings, function (projectRating) {
    return _c('span', {
      key: projectRating.ratingTitle
    }, [_c('strong', {
      staticClass: "mr-3"
    }, [_vm._v(_vm._s(projectRating.rating))]), _vm._v(" " + _vm._s(projectRating.ratingTitle))]);
  }), _c('br')], 2), _c('a', {
    staticClass: "text-primary font-w600",
    attrs: {
      "href": _vm.carbon.project.registryLink
    }
  }, [_vm._v("Registry Listing "), _c('i', {
    staticClass: "fa fa-arrow-up-right-from-square fa-sm"
  })])], 1), _c('SectionTitle', [_vm._v("Location")]), _c('GoogleMap', {
    attrs: {
      "map-config": _vm.mapOptions,
      "markers": _vm.mapMarkers
    }
  })], 1)])]), _c('ConfirmModal', {
    attrs: {
      "title": "Coming Soon",
      "open": _vm.constructionModal,
      "hide-submit": ""
    },
    on: {
      "close": function ($event) {
        _vm.constructionModal = false;
      }
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('i', {
    staticClass: "fad fa-triangle-person-digging text-primary fa-4x mb-3"
  }), _c('p', {
    staticClass: "font-w600"
  }, [_vm._v("We are still working on the ability to self-service purchase carbon projects through Etainabl.")]), _c('p', [_vm._v(" In the meantime, please contact us at "), _c('a', {
    attrs: {
      "href": "mailto:support@etainabl.com"
    }
  }, [_vm._v("support@etainabl.com")]), _vm._v(", and we can help you purchase a carbon project. ")])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }